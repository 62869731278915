import React, { useRef, useState, useEffect, useMemo, createRef } from "react";
import { useInView } from "react-intersection-observer";

import { useStore } from "../store/store";

const ImageThumb = ({ img, onClick, lazy = true }) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100%"
  });

  const [isLoaded, setLoaded] = useState(0);

  const [showImage, setShowImage] = useState(false)

  // const showImage = lazy === 'false' || !!inView

  useEffect(() => {
    if(!lazy || inView){
      return setShowImage(true)
    }
  }, [inView, lazy])

  // When an image is in the browser cache or is completed loading before react rehydration,
  // the `onload` may not be triggered. In order to ensure we have the correct "complete"
  // state, check the `complete` property after mounting
  const imgRef = createRef();
  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth
    ) {
      setLoaded(true);
    }
  });
  return (
    <div
      ref={ref}
      className="relative overflow-hidden rounded cursor-pointer pt-full group"
      onClick={onClick}
    >
      <img
        className={`absolute top-0 object-cover w-full h-full transition-all duration-300 transform group-hover:opacity-50 group-hover:scale-110`}
        ref={imgRef}
        style={{}}
        src={showImage ? img : undefined}
        onLoad={() => setLoaded(true)}
        style={{ opacity: isLoaded ? 1 : 0 }}
      />
    </div>
  );
};
const ImageGallery = ({ images }) => {
  const openLightBox = useStore((state) => state.openLightBox);
  return (
    <>
      <div className="w-full">
        <div className="grid grid-cols-4 gap-6 xl:grid-cols-4 2xl:grid-cols-4">
          {images.map((image, idx) => (
            <ImageThumb
              key={idx}
              img={`/assets/images/studio/gallery-thumbs/${image.img}`}
              onClick={() => openLightBox(images, idx)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageGallery;
