import { useState, useEffect, createRef } from "react";
import Img from "react-optimized-image";

import { useInView } from "react-intersection-observer";

// Utils
import truncate from "../../utils/truncate";

import LazyImage from "../LazyImage";

import BGImg from "../../images/careers/careers-bg-alt.jpg";
import BGImgLqip from "../../images/careers/careers-bg-alt.jpg?lqip";

const Filter = ({ onChange, data = [] }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [showAll, setShowAll] = useState(false);

  const options = [
    { label: "All", value: "", count: data.length },
    {
      label: "Animators",
      value: "Animation",
      count: data.filter((position) => position.department === "Animation")
        .length,
    },
    {
      label: "Artists",
      value: "Artist",
      count: data.filter((position) => position.department === "Artist").length,
    },

    {
      label: "Audio",
      value: "Audio",
      count: data.filter((position) => position.department === "Audio").length,
    },
    {
      label: "Designers",
      value: "Design",
      count: data.filter((position) => position.department === "Design").length,
    },
    {
      label: "Engineers",
      value: "Engineers",
      count: data.filter((position) => position.department === "Engineers")
        .length,
    },
    {
      label: "Operations",
      value: "Operations",
      count: data.filter((position) => position.department === "Operations")
        .length,
    },
    {
      label: "Producers",
      value: "Producers",
      count: data.filter((position) => position.department === "Producers")
        .length,
    },
    {
      label: "Quality Assurance",
      value: "QA",
      count: data.filter((position) => position.department === "QA").length,
    },
  ];

  const handleSelect = (option) => {
    if (option.value === selectedValue) {
      setShowAll(true);
    } else {
      setSelectedValue(option.value);
      onChange(option.value);
      setShowAll(false);
    }
  };

  return (
    <div className="flex flex-col xl:flex-row xl:space-x-5">
      {options.map((option) => (
        <span
          key={option.value}
          onClick={() => option.count > 0 && handleSelect(option)}
          className={`relative font-tall overflow-y-hidden max-h-0 transition-all duration-150  text-right xl:overflow-y-visible ${
            option.count > 0
              ? "cursor-pointer hover:opacity-100 opacity-60"
              : "opacity-20"
          } ${
            option.value === selectedValue
              ? "opacity-100 max-h-10 xl:max-h-14"
              : "xl:max-h-14"
          } ${showAll && "max-h-10 xl:max-h-14"}`}
        >
          {option.label}
          {option.count ? (
            <span className="absolute top-0 right-0 hidden px-1 py-0 font-sans text-xs font-bold tracking-wide text-white transform translate-x-4 -translate-y-2 rounded-full bg-orange bg-opacity-20 xl:block">
              {" "}
              {option.count}
            </span>
          ) : null}
        </span>
      ))}
    </div>
  );
};

const CareerCard = ({ title, link, image, isSelected, defaultState, lazy = true }) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100%"
  });

  const [isLoaded, setLoaded] = useState(0);

  const [showImage, setShowImage] = useState(false)

  // const showImage = lazy === 'false' || !!inView

  useEffect(() => {
    if(!lazy || inView){
      return setShowImage(true)
    }
  }, [inView, lazy])

  // When an image is in the browser cache or is completed loading before react rehydration,
  // the `onload` may not be triggered. In order to ensure we have the correct "complete"
  // state, check the `complete` property after mounting
  const imgRef = createRef();
  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth
    ) {
      setLoaded(true);
    }
  });
  return (
    <article
      ref={ref}
      className={`relative flex flex-col w-full max-w-sm mx-auto rounded overflow-hidden transform transition-all duration-300 border-black group hover:scale-105 md:max-w-4xl ${
        defaultState || isSelected
          ? "opacity-100"
          : "opacity-30 pointer-events-none"
      }`}
    >
      <div className="relative bg-gray-200 pb-2/3">
        <div className={`absolute top-0 w-full h-full bg-gray-200 transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}>
          <img
            className={`absolute top-0 object-cover w-full h-full transition-all duration-300 transform scale-100
              ${
                defaultState
                  ? "opacity-20 group-hover:opacity-100 group-hover:scale-105 "
                  : isSelected && !defaultState
                  ? "opacity-100 group-hover:opacity-100 group-hover:scale-105"
                  : "opacity-40"
              }`}
              alt={title}
              ref={imgRef}
              style={{}}
              src={showImage ? image : undefined}
              loading="lazy"
              width="230"
              height="350"
              onLoad={() => setLoaded(true)}
          />
        </div>
        <div className="absolute inset-0 flex flex-col p-3 transition-all duration-300 transform lg:p-6 group-hover:scale-105">
          <header className="mt-auto">
            <h3 className="text-2xl text-gray-900 lg:text-5xl xl:text-5xl font-tall">
              {truncate(title, 30)}
            </h3>
          </header>
        </div>

        <a href={`${link}`} target="_blank" className="absolute inset-0"></a>
      </div>
    </article>
  );
};

const CareersSection = ({ positions }) => {
  const [selectedTag, setSelectedTag] = useState("");

  return (
    <div className="relative overflow-hidden" data-scroll id="careers-wrapper">
      <LazyImage
        className="absolute top-0 right-0 object-cover w-full h-full"
        data-scroll-target="#careers-wrapper"
        data-scroll-speed="-0.5"
        data-scroll
        src={BGImg}
        lqip={BGImgLqip}
        observerOptions={{ threshold: 0.5 }}
      >
        <Img src={BGImg} webp sizes={[400, 1200, 2800]} />
      </LazyImage>
      <div
        className="absolute top-0 w-full h-32 pointer-events-none bg-gradient-to-b from-black to-transparent"
        role="presentation"
      ></div>

      <div className="relative flex flex-col flex-1 px-5 py-12 overflow-hidden lg:py-64">
        <header className="relative mb-10 lg:mb-16">
          <img
            className="absolute top-0 object-contain w-full h-full px-5 opacity-40"
            src={require("../../images/titles/careers-white.svg")}
            loading="lazy"
            data-scroll-target="#careers-wrapper"
            data-scroll-speed="-0.5"
            data-scroll
          />
          <div
            className="relative flex flex-col items-center mt-20 mb-12"
            data-scroll-target="#careers-wrapper"
            data-scroll-speed="-0.3"
            data-scroll
          >
            <h2 className="flex flex-col text-2xl leading-5 text-center text-white md:leading-9 md:text-5xl font-wide lg:text-5xl lg:leading-10">
              <span
                data-scroll-target="#careers-wrapper"
                data-scroll-speed="-0.3"
                data-scroll-direction="horizontal"
                data-scroll
              >
                Find A Home
              </span>{" "}
              <span
                data-scroll-target="#careers-wrapper"
                data-scroll-speed="0.3"
                data-scroll-direction="horizontal"
                data-scroll
              >
                With Us
              </span>
            </h2>
            {/* <img
              className="w-full max-w-md mt-3"
              src={require("../../images/paint-streak-e.svg")}
              loading="lazy"
            /> */}
          </div>
        </header>
        <div className="flex w-full py-6 mx-auto text-3xl text-white max-w-7xl ">
          <span className="mr-auto font-tall">Filter By:</span>
          <Filter
            onChange={(value) => setSelectedTag(value)}
            data={positions}
          />
        </div>
        <div className="relative w-full mx-auto max-w-7xl">
          {/* <div className="absolute bottom-0 w-full border-2 opacity-75 pointer-events-none -top-24 border-orange"></div> */}
          <div className="grid w-full grid-cols-2 gap-6 md:grid-cols-4">
            {positions
              ?.sort((a, b) =>
                a.title > b.title ? 1 : b.title > a.title ? -1 : 0
              )
              .map((position) => (
                <CareerCard
                  title={position.title}
                  image={position["image"]}
                  department={position.department}
                  link={position.external_link}
                  key={position.title}
                  isSelected={position.department === selectedTag}
                  defaultState={selectedTag === ""}
                />
              ))}
          </div>
          <footer className="flex justify-center my-10">
            {/* <div className="flex space-x-3 md:space-x-4">
              <a
                href="#"
                className="flex items-center py-2 pl-3 pr-5 text-lg text-white transition-colors duration-150 bg-black hover:bg-white hover:text-black"
              >
                <RiTeamLine className="w-5 h-5 mr-3" />
                <span>View All Positions</span>
              </a>
            </div> */}
          </footer>
        </div>
        <img
          className="absolute left-0 w-full h-auto -bottom-1"
          src={require("../../images/backgrounds/contact-top-cap.png")}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default CareersSection;
