import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Img from "react-optimized-image";

import LazyImage from "../LazyImage";

import BGImg from "../../images/values-alt/bg.jpg";
import BGImgLqip from "../../images/values-alt/bg.jpg?lqip";

const Value = ({ title, body, offset = "0" }) => {
  const item = {
    hidden: { opacity: 0, x: -50 },
    show: { opacity: 1, x: 0 },
  };
  return (
    <motion.div
      className="text-base text-left sm:text-xl lg:text-2xl xl:text-4xl 2xl:text-5xl"
      variants={item}
      style={{ marginLeft: offset, marginBottom: "0.5em" }}
    >
      <h4
        className="font-tall"
        style={{ fontSize: "1.5em", lineHeight: "0.9em" }}
      >
        {title}
      </h4>
      <p _style={{ fontSize: "0.8em" }} className="text-sm xl:text-lg">
        {body}
      </p>
    </motion.div>
  );
};

export const DisplayValue = ({ show, children }) => {
  const container = {
    hidden: {
      opacity: 0.5,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.1,
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div
      className={`absolute z-10 inset-0 pointer-events-none flex flex-col items-center transform justify-center text-center text-white transition-all duration-300 ${
        show ? "_opacity-100" : "_opacity-0"
      }`}
    >
      {/* <AnimatePresence> */}
      <motion.div
        className="z-10 pointer-events-none "
        // key={show}
        // initial={{ _y: 50, opacity: 0, _scale: 1.1 }}
        // animate={{ y: 0, opacity: 1, scale: 1 }}
        // exit={{ _y: -50, opacity: 0, _scale: 0.9 }}
        // transition={{ ease: "easeOut", duration: 0.3, staggerChildren: 1 }}
        variants={container}
        initial="hidden"
        animate={show ? "show" : "hidden"}
      >
        {children}
      </motion.div>
      {/* </AnimatePresence> */}
    </div>
  );
};

const GamesSection = () => {
  const [showStudio, setShowStudio] = useState(false);
  const [showTeam, setShowTeam] = useState(false);

  useEffect(() => {
    if (window?.innerWidth >= 768) {
    } else {
      setShowStudio(true);
    }
  }, []);

  const handleShowTeam = () => {
    setShowStudio(false);
    setShowTeam(true);
  };

  const handleShowStudio = () => {
    setShowStudio(true);
    setShowTeam(false);
  };

  const handleShowNone = () => {
    if (window?.innerWidth >= 768) {
      setShowStudio(false);
      setShowTeam(false);
    }
  };

  return (
    <div className="relative bg-gray-900" data-scroll id="values-wrapper">
      <LazyImage
        className="absolute top-0 right-0 object-cover w-full h-full"
        src={BGImg}
        lqip={BGImgLqip}
        observerOptions={{ threshold: 0.5 }}
      >
        <Img src={BGImg} webp sizes={[400, 1200, 2800]} />
      </LazyImage>
      <div
        className="absolute top-0 w-full h-32 pointer-events-none bg-gradient-to-b from-black to-transparent"
        role="presentation"
      ></div>
      <img
        className="absolute right-0 z-10 object-contain object-right h-full pointer-events-none"
        style={{ marginTop: "15vw", width: "20%" }}
        src={require("../../images/values-alt/paint-streak-right.png")}
        loading="lazy"
        data-scroll=""
        data-scroll-speed="2"
        data-scroll-position="top"
        data-scroll-target="#values-wrapper"
      />
      <img
        className="absolute left-0 z-10 object-contain object-left h-full pointer-events-none w-1/8"
        style={{ marginTop: "4vw", width: "15%" }}
        src={require("../../images/values-alt/paint-streak-left.png")}
        loading="lazy"
        data-scroll=""
        data-scroll-speed="1"
        data-scroll-position="top"
        data-scroll-target="#values-wrapper"
      />
      <div
        className="relative flex flex-col justify-center flex-1 overflow-hidden"
        style={{ padding: "20vw 0 30vw" }}
      >
        <div className="relative z-10 flex flex-col items-center justify-center max-w-6xl px-16 mx-auto space-y-5 text-lg text-justify text-white md:px-28 lg:text-xl xl:px-64 mb-36 xl:mb-48">
          <p>
            At Unbroken Studios we strive for a culture where employees can be
            their best selves and feel valued. We believe that workplaces and
            gaming communities must be diverse and inclusive of all people
            regardless of race, sex, religion, ethnicity, disability, sexual
            orientation, or gender identity. We affirm that trans women are
            women, trans men are men, and people of all varied gender identities
            have the right to exist and to thrive in gaming spaces and society
            as a whole.
          </p>
          <p>
            Our games are created by a multicultural team of diverse backgrounds
            and identities. Our teams’ unique experiences and perspectives drive
            our vision and passion for making great games. We don’t always agree
            with the views of the IP creators, but we always find beauty and joy
            in creating inclusive spaces and experiences.
          </p>
        </div>
        <div className="relative">
          <div className="relative grid w-full grid-cols-7 py-12 mx-auto max-w-8xl lg:gap-8 lg:py-12">
            <img
              className="absolute object-contain w-full h-full"
              src={require("../../images/values/values-paint-streak.svg")}
              loading="lazy"
            />
            <div
              className="relative flex flex-col items-end col-span-3"
              data-scroll
              data-scroll-speed="-1"
              data-scroll-offset="0%,100%"
              data-scroll-direction="horizontal"
              data-scroll-target="#values-wrapper"
            >
              <div
                className={`relative text-white transform transition-all duration-700 cursor-pointer ${
                  showTeam
                    ? "opacity-50 md:opacity-0 md:translate-x-1/4"
                    : "delay-500"
                } `}
                onMouseOver={handleShowStudio}
                onMouseOut={handleShowNone}
              >
                <h3
                  className="flex flex-col text-5xl sm:text-7xl lg:text-7xl font-tall xl:text-9xl 2xl:text-10xl"
                  style={{ lineHeight: "0.9em" }}
                >
                  <span>STUDIO</span>
                  <span style={{ marginLeft: "3vw" }}>VALUES</span>
                </h3>
                <h4 className="lg:text-lg" style={{ marginLeft: "3vw" }}>
                  Our Culture
                </h4>
              </div>
            </div>
            <div className="col-span-1"></div>
            <div
              className="relative flex flex-col items-start col-span-3"
              data-scroll
              data-scroll-speed="1"
              data-scroll-offset="0%,100%"
              data-scroll-direction="horizontal"
              data-scroll-target="#values-wrapper"
            >
              <div
                className={`relative text-white transform transition-all duration-700 cursor-pointer ${
                  showStudio
                    ? "opacity-50 md:opacity-0 md:translate-x-1/4"
                    : "delay-500"
                } `}
                onMouseOver={handleShowTeam}
                onMouseOut={handleShowNone}
              >
                <h3
                  className="flex flex-col text-5xl sm:text-7xl lg:text-7xl font-tall xl:text-9xl 2xl:text-10xl"
                  style={{ lineHeight: "0.9em" }}
                >
                  <span>TEAM</span>
                  <span className="ml-5">VALUES</span>
                </h3>
                <h4 className="ml-5 lg:text-lg">How We Work</h4>
              </div>
            </div>
          </div>
          <div className="relative inset-0 flex justify-between mx-auto pointer-events-none h-80 md:h-full md:absolute max-w-8xl">
            <div className="absolute inset-0 md:relative md:w-1/2">
              <DisplayValue show={showTeam}>
                <Value
                  title="Efficiency"
                  body="We move fast &amp; stay nimble"
                  offset="0"
                />
                <Value
                  title="Quality"
                  body="We hold high standards in our craft"
                  offset="3vw"
                />
                <Value
                  title="Ownership"
                  body="We own our responsibilities"
                  offset="6vw"
                />
                <Value
                  title="Integrity"
                  body="We deliver on our promises"
                  offset="9vw"
                />
                <Value
                  title="Mindset"
                  body="We are optimistic problem solvers"
                  offset="12vw"
                />
              </DisplayValue>
            </div>
            <div className="absolute inset-0 md:relative md:w-1/2">
              <DisplayValue show={showStudio}>
                <Value
                  title="Empowerment"
                  body="We empower individual impact"
                  offset="0"
                />
                <Value
                  title="Empathy"
                  body="We put people first"
                  offset="3vw"
                />
                <Value
                  title="Growth"
                  body="We prioritize learning &amp; growth"
                  offset="6vw"
                />
                <Value
                  title="Inclusion"
                  body="We foster diversity &amp; inclusion"
                  offset="9vw"
                />
                <Value
                  title="Family"
                  body="We are a close-knit family"
                  offset="12vw"
                />
              </DisplayValue>
            </div>
          </div>
        </div>
      </div>
      {/* <img
          className="absolute top-0 left-0 w-full h-auto -mt-1"
          src={require("../../images/backgrounds/history-bottom-cap.png")}
        /> */}
      <img
        className="absolute bottom-0 left-0 z-20 w-full h-auto -mb-1 pointer-events-none"
        src={require("../../images/backgrounds/team-top-cap.png")}
        loading="lazy"
      />
    </div>
  );
};

export default GamesSection;
