import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";
import Img from "react-optimized-image";

import ImageGallery from "../ImageGallery";

import LazyImage from "../LazyImage";

import BGImg from "../../images/studio/bg.jpg";
import BGImgLqip from "../../images/studio/bg.jpg?lqip";

const StudioSection = () => {
  const [map, setMap] = useState(null);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  useEffect(() => {
    if (map) return;
    const Map = dynamic(() => import("../Map"), {
      loading: () => (
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="w-40 h-40 spinner"></span>
        </div>
      ),
    });
    setMap(<Map />);
  }, [inView]);

  const images = [
    {
      img: "studio-image-1.jpg",
    },
    {
      img: "studio-image-6.jpg",
    },
    {
      img: "studio-image-3.jpg",
    },
    {
      img: "studio-image-9.jpg",
    },
    {
      img: "studio-image-4.jpg",
    },
    {
      img: "studio-image-10.jpg",
    },
    {
      img: "studio-image-5.jpg",
    },

    {
      img: "studio-image-11.jpg",
    },
  ];

  return (
    <div
      className="relative pt-12 -mt-1 bg-white"
      data-scroll
      id="studio-wrapper"
      ref={ref}
    >
      <img
        className="absolute top-0 left-0 z-10 w-full h-auto pointer-events-none"
        data-scroll
        data-scroll-target="#studio-wrapper"
        data-scroll-speed="-0.5"
        loading="lazy"
        src={require("../../images/backgrounds/social-bottom-cap-alt.png")}
      />
      <div className="relative flex flex-col justify-center flex-1 overflow-hidden bg-black lg:flex-row lg:h-hero 2xl:h-hero-lg">
        <div className="h-screen-half lg:h-full lg:w-1/2">{map}</div>
        <img
          className="absolute top-0 hidden object-cover w-auto h-full transform -translate-x-1/2 lg:block left-1/2"
          src={require("../../images/studio/border.png")}
          loading="lazy"
        />
        <div className="relative flex flex-col justify-center overflow-y-auto bg-black lg:pt-20 lg:h-full lg:w-1/2 xl:pt-32">
          <LazyImage
            className="absolute top-0 right-0 object-cover w-full h-full"
            src={BGImg}
            lqip={BGImgLqip}
            observerOptions={{ threshold: 0.5 }}
          >
            <Img src={BGImg} webp sizes={[400, 1200, 2800]} />
          </LazyImage>

          <div className="relative max-w-3xl p-5 py-12 mx-auto text-white">
            <header>
              <h2 className="text-3xl xl:text-6xl font-wide-bold">
                Unbroken Studios HQ
              </h2>
              <address className="my-3 not-italic">
                2120 Park Place, Suite 110, El Segundo, 90245, United States
              </address>
            </header>
            <div className="my-12 lg:my-8">
              {/* <img
                className="w-full h-auto"
                loading="lazy"
                src={require("../../images/studio/video-placeholder.png")}
              /> */}

              <ImageGallery images={images} />

              <div className="my-8 leading-7 prose text-gray-300 prose-dark">
                <p>
                  Located in the sunny LA South Bay, we are moments away from
                  the{" "}
                  <a
                    href="https://www.google.com/search?q=manhattan+beach&safe=off&rlz=1C1CHBF_enUS925US925&sxsrf=ALeKk02TXEARoiUSIru7-4V2DY-gxWZNkw:1614790794555&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiwlMi9zJTvAhVGJTQIHQIgAlEQ_AUoA3oECB4QBQ&biw=2560&bih=1298&dpr=1.5"
                    target="_blank"
                  >
                    beach
                  </a>
                  ,{" "}
                  <a href="https://thepointsb.com/" target="_blank">
                    abundant shopping, bars &amp; restaurants
                  </a>{" "}
                  and an{" "}
                  <a
                    href="https://www.niche.com/k12/d/manhattan-beach-unified-school-district-ca/"
                    target="_blank"
                  >
                    A+ ranked public school district
                  </a>
                  .
                </p>
                <p>
                  We have monthly family BBQs, weekly film nights, annual studio
                  parties and, with the nearby bars &amp; restaurants, we make
                  sure there are plenty opportunities to hang out as a team!
                </p>
                <p>
                  Overall, we aim for an inclusive philosophy to games
                  development, where everyone feels appreciated and we balance
                  hard work with plenty of moments to relax, reflect and
                  celebrate a job well done.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute z-10 h-auto -left-32 -right-3 -bottom-1"
        src={require("../../images/backgrounds/studio-bottom-cap.svg")}
        loading="lazy"
      />
    </div>
  );
};

export default StudioSection;
