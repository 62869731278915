import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { SmoothScrollContext } from "../../contexts/SmoothScroll.context";
import Img from "react-optimized-image";

const GameBanner = ({
  title,
  description,
  meta,
  year,
  image = null,
  isActive,
  isDefault,
  onMouseOver,
  onMouseOut,
  index = 0,
}) => {
  return (
    <div
      className="relative flex w-full h-full p-5 text-white transition-opacity duration-700 bg-black cursor-pointer md:p-6 lg:p-8 xl:p-10 font-tall bg-opacity-80"
      // animate={{
      //   opacity: isActive ? 1 : 0,
      // }}
      // transition={{
      //   duration: 0.7,
      //   delay: 0.3,
      // }}

      style={{
        opacity: isActive ? 1 : 0,
        willChange: "opacity",
      }}
    >
      <div
        style={{
          opacity: isDefault ? 0.3 : 0,
        }}
        className={`absolute top-0 bottom-0 left-0 right-0 filter-grayscale transition-opacity duration-700 ${
          image === null ? "bg-black" : ""
        }`}
      >
        {image}
      </div>
      <div
        className="flex w-full h-full"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-target="#games-history-wrapper"
        data-scroll-speed={`-${index * 0.3}`}
      >
        <div
          className={`relative flex flex-col justify-center h-full mr-3 md:mr-5 lg:mr-8`}
        >
          <span className="text-4xl md:text-7xl lg:text-9xl xl:text-10xl">
            {year}
          </span>
        </div>
        <div
          className={`relative flex flex-col justify-center h-full`}
          
        >
          <h3 className="text-3xl md:text-7xl lg:text-7xl text-orange">
            {title}
          </h3>
          <div className="flex">
            <p className="text-xl normal-case _font-sans lg:text-3xl">
              {" "}
              {description}.
              {meta && (
                <span className="font-medium capitalize "> {meta}.</span>
              )}
            </p>
          </div>
        </div>
        <div className={`absolute top-0 bottom-0 left-0 right-0 md:hidden`} onClick={() => {
          // if(isActive){
          //   onMouseOut()

          // } else {
          //   onMouseOver()
          // }
          if(isActive && isDefault){
onMouseOver()
          } else {
onMouseOut()
          }
        }}></div>

        <div className="absolute top-0 bottom-0 left-0 right-0 hidden md:block" onMouseOver={onMouseOver}
          onMouseLeave={onMouseOut}></div>
      </div>
    </div>
  );
};

const GameImage = ({ game }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="absolute top-0 object-cover w-full h-full bg-black"
        style={{
          marginLeft: -50,
          marginRight: -50,
          width: "calc(100% + 100px)",
        }}
        key={game?.title}
        width="2200"
        height="1400"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 50 }}
        transition={{ ease: "easeOut", duration: 0.7 }}
      >
        {game?.image || null}
      </motion.div>
    </AnimatePresence>
  );
};

const StudioHistorySection = () => {
  const { scroll } = useContext(SmoothScrollContext);

  const [currentIndex, setCurrentIndex] = useState(null);

  const games = [
    {
      title: "Harry Potter: Quidditch Champions",
      description: "Online Multiplayer Team Sport Game Available Sep 3, 2024 for PS5 / PS4, Xbox Series X|S / Xbox One, Nintendo Switch and PC",
      year: "2024",
      image: (
        <Img
          className="absolute top-0 object-cover object-top w-full h-full bg-black"
          style={{
            objectPosition: "50% 50%",
          }}
          src={require("../../images/studio-history/quidditch.jpg")}
          webp
          sizes={[1200, 2200]}
        />
      ),
    },
    {
      title: "SUICIDE SQUAD: KILL THE JUSTICE LEAGUE",
      description: "Co-Dev. PS5, Xbox Series X|S & PC",
      year: "2024",
      image: (
        <Img
          className="absolute top-0 object-cover object-right w-full h-full bg-black lg:object-center"
          src={require("../../images/studio-history/history-3.jpg")}
          webp
          sizes={[1200, 2200]}
        />
      ),
    },
    {
      title: "FRACTURED LANDS",
      description: "Self Published. PC",
      meta: "100 person battle royale",
      year: "2018",
      image: (
        <Img
          className="absolute top-0 object-cover w-full h-full bg-black opacity-50 lg:opacity-100"
          src={require("../../images/studio-history/history-2.jpg")}
          webp
          sizes={[800, 1200, 2200]}
        />
      ),
    },
    {
      title: "FANTASTIC PLASTIC SQUAD",
      description: "Self Published. Mobile",
      meta: "Free to play Multiplayer Shooter",
      year: "2015",
      image: (
        <Img
          className="absolute top-0 object-cover w-full h-full bg-black opacity-50 lg:opacity-100"
          src={require("../../images/studio-history/history-1.jpg")}
          webp
          sizes={[800, 1200, 2200]}
        />
      ),
    },
  ];

  return (
    <div className="relative bg-black" id="games-history-wrapper" data-scroll>
      <div className="relative flex flex-col justify-center flex-1 overflow-hidden bg-black _xl:h-hero-lg">
        <div
          className="absolute top-0 object-cover w-full h-full bg-black"
          data-scroll-target="#games-history-wrapper"
        >
          <div
            className="absolute top-0 w-full h-full"
            data-scroll=""
            data-scroll-speed="-1.5"
          >
            <GameImage game={games[currentIndex]} />
          </div>
        </div>

        <div className="relative top-0 flex w-full h-full ">
          <div className="flex flex-1">
            <nav className="flex flex-col flex-1">
              {games.map((game, index) => (
                <GameBanner
                  key={index}
                  title={game.title}
                  description={game.description}
                  meta={game.meta}
                  year={game.year}
                  image={game.image}
                  isActive={index === currentIndex || currentIndex === null}
                  isDefault={currentIndex === null}
                  onMouseOver={() => setCurrentIndex(index)}
                  onMouseOut={() => setCurrentIndex(null)}
                  index={index}
                />
              ))}
            </nav>
          </div>
        </div>
      </div>
      {/* <img
        className="absolute top-0 left-0 w-full h-auto pointer-events-none"
        style={{ marginTop: "-2.7vw" }}
        src={require("../../images/backgrounds/history-bottom-cap-fade.png")}
        loading="lazy"
      />
      <img
        className="absolute bottom-0 left-0 w-full h-auto -mb-1 pointer-events-none"
        src={require("../../images/backgrounds/values-top-cap-black.svg")}
        loading="lazy"
      /> */}
    </div>
  );
};

export default StudioHistorySection;
