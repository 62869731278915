import React, { useState, useEffect, createRef } from "react";
import RSSlider from "react-slick";

import { useInView } from "react-intersection-observer";

// Utils
import truncate from "../utils/truncate";

import jsonSettings from "../content/settings.json";

const SocialCard = ({
  caption,
  date,
  url = "",
  img,
  type = "IMAGE",
  lazy = true,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100%",
  });

  const [isLoaded, setLoaded] = useState(type === "VIDEO" ? true : false);

  const [showImage, setShowImage] = useState(type === "VIDEO" ? true : false);

  // const showImage = lazy === 'false' || !!inView

  useEffect(() => {
    if (!lazy || inView) {
      return setShowImage(true);
    }
  }, [inView, lazy]);

  // When an image is in the browser cache or is completed loading before react rehydration,
  // the `onload` may not be triggered. In order to ensure we have the correct "complete"
  // state, check the `complete` property after mounting
  const imgRef = createRef();
  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth
    ) {
      setLoaded(true);
    }
  });
  var dateParsed = new Date(date).toLocaleDateString();
  return (
    <article
      ref={ref}
      className="relative flex flex-col w-full max-w-sm mx-auto overflow-hidden transition-colors duration-150 rounded shadow bg-orange group md:max-w-4xl"
    >
      <div className="relative pb-full">
        <div
          className={`absolute w-full h-full transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          {type === "IMAGE" && (
            <img
              className="absolute top-0 object-cover w-full h-full transition-opacity duration-150 opacity-100 group-hover:opacity-20"
              alt={caption && truncate(caption, 200)}
              ref={imgRef}
              style={{}}
              src={showImage ? img : undefined}
              width="230"
              height="350"
              onLoad={() => setLoaded(true)}
            />
          )}
          {type === "CAROUSEL_ALBUM" && (
            <img
              className="absolute top-0 object-cover w-full h-full transition-opacity duration-150 opacity-100 group-hover:opacity-20"
              alt={caption && truncate(caption, 200)}
              ref={imgRef}
              style={{}}
              src={showImage ? img : undefined}
              width="230"
              height="350"
              onLoad={() => setLoaded(true)}
            />
          )}
          {type === "VIDEO" && (
            <video width="1024" height="1024" loop="true" muted="true" autoPlay="true" playsInline="true" className="absolute top-0 object-cover w-full h-full transition-opacity duration-150 opacity-100 group-hover:opacity-20">
            <source src={img} type="video/mp4"/>
            Your browser does not support the video tag.
          </video> 
          )}
        </div>
        <div className="absolute inset-0 flex flex-col justify-center p-6 transition-opacity duration-150 opacity-0 group-hover:opacity-100">
          <div className="flex flex-col text-white transition-colors duration-150">
            {caption && truncate(caption, 200)}
          </div>
          <header className="absolute w-full bottom-3">
            <h3 className="text-4xl text-white sm:text-3xl lg:text-5xl xl:text-6xl font-tall opacity-90">
              {dateParsed}
            </h3>
          </header>
        </div>

        {/* <img
          className="absolute top-0 object-cover w-full h-full transition-opacity duration-150 opacity-100 group-hover:opacity-0"
          src={img}
          loading="lazy"
          width="1024"
          height="1024"
          alt={caption && truncate(caption, 200)}
        /> */}

        <a href={`${url}`} target="_blank" className="absolute inset-0"></a>
      </div>
    </article>
  );
};

const InstagramSlider = () => {
  const [posts, setPosts] = useState([]);
  // const onSlideChange = (index) => console.log(`changed to slide ${index}`);
  const setSlideCustom = () => 1;

  const sliderRef = React.useRef();

  useEffect(() => {
    const fetchPosts = async () => {
      const INSTAGRAM_ID = "8628614913";
      const INSTAGRAM_USER_ID = "17841408607418576";
      let ACCESS_TOKEN = "IGQVJYdTZAuaENmZA19TZA1hIeWVQVXhwYjltV28xV3VtT2hlNDFlVzBRdTRhTUtOOVUtaGMzNTZAURFptV0lsQWxGWmdDb3NRZAEptXzdVSnd0V05YSVR2NE9tMUROSU40eEM0TDdpVTlB";

      ACCESS_TOKEN = jsonSettings.instagram_access_token;

      try {
        const response = await fetch(
          //`https://www.instagram.com/graphql/query?query_id=17888483320059182&variables={"id":"${INSTAGRAM_ID}","first":${PHOTO_COUNT},"after":null}`
          //`https://graph.instagram.com/${INSTAGRAM_USER_ID}/media?access_token=${ACCESS_TOKEN}`
          `https://graph.instagram.com/${INSTAGRAM_USER_ID}/media?fields=media_url,thumbnail_url,caption,media_type,like_count,shortcode,timestamp,comments_count,username,children{media_url},permalink&limit=${10}&access_token=${ACCESS_TOKEN}`
        );
        const { data } = await response.json();

        const posts = data;

        setPosts(posts);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPosts();
  }, []);

  const BulletComponent = ({ onClick, isActive }) => (
    <li
      className={`w-3 h-3 bg-white ${
        isActive ? "opacity-100" : "opacity-50"
      } rounded-full mx-2`}
      onClick={onClick}
    />
  );

  const ArrowComponent = ({ className, onClick, direction }) => {
    return (
      <div
        className={`invisible text-white transition-opacity duration-200 transform -translate-y-1/2 cursor-pointer top-1/2 absolute z-20 _opacity-0 group-hover:opacity-100 lg:visible ${
          direction === "right" ? "right-0" : "left-0"
        }`}
        onClick={onClick}
      >
        {direction === "left" && (
          <div className="p-4 transition-transform duration-200 transform hover:-translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M40.863 48.898a2.024 2.024 0 01-1.014-.271L1.014 26.206A2.029 2.029 0 010 24.45a2.026 2.026 0 011.014-1.755L39.849.273a2.029 2.029 0 012.028 0 2.03 2.03 0 011.014 1.757v44.843a2.03 2.03 0 01-1.014 1.756 2.027 2.027 0 01-1.014.269zM6.083 24.449l32.752 18.909V5.54z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
        {direction === "right" && (
          <div className="p-5 transition-transform duration-200 transform hover:translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M2.023 48.898a2.024 2.024 0 001.014-.271l38.835-22.421a2.029 2.029 0 001.014-1.756 2.026 2.026 0 00-1.014-1.755L3.037.273a2.029 2.029 0 00-2.028 0A2.03 2.03 0 00-.005 2.03v44.843a2.03 2.03 0 001.014 1.756 2.027 2.027 0 001.014.269zm34.78-24.449L4.055 43.355V5.537z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const ArrowPrev = (props) => {
    const { onClick } = props;
    return <ArrowComponent onClick={onClick} direction="left" />;
  };

  const ArrowNext = (props) => {
    const { onClick } = props;
    return <ArrowComponent onClick={onClick} direction="right" />;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    // nextArrow: <ArrowNext />,
    // prevArrow: <ArrowPrev />,
    // adaptiveHeight: true,
    //onLazyLoad: (slidesLoaded) => console.log(slidesLoaded),
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  if (!posts || posts.length === 0) {
    return null;
  }

  return (
    // <div className="w-full h-full group">
    <RSSlider ref={sliderRef} {...settings} className="mx-1 _group">
      {posts.map((post, i) => (
        <div
          className="flex flex-1 w-full h-full p-1"
          key={i}
          draggable="false"
        >
          {" "}
          <SocialCard
            caption={post.caption}
            date={post.timestamp}
            img={post.media_url}
            type={post.media_type}
            url={post.permalink}
          />
        </div>
      ))}
    </RSSlider>
    // </div>
  );
};

export default InstagramSlider;
