import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { useState, useEffect, createRef } from "react";

import { useInView } from "react-intersection-observer";

const ContactCard = ({
  title,
  name,
  job,
  email,
  img,
  twitterLink = null,
  linkedInLink = null,
  lazy = true,
  ...props
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100%"
  });

  const [isLoaded, setLoaded] = useState(0);

  const [showImage, setShowImage] = useState(false)

  // const showImage = lazy === 'false' || !!inView

  useEffect(() => {
    if(!lazy || inView){
      return setShowImage(true)
    }
  }, [inView, lazy])

  // When an image is in the browser cache or is completed loading before react rehydration,
  // the `onload` may not be triggered. In order to ensure we have the correct "complete"
  // state, check the `complete` property after mounting
  const imgRef = createRef();
  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth
    ) {
      setLoaded(true);
    }
  });
  return (
    <article
      ref={ref}
      data-scroll-target="#contact-wrapper"
      data-scroll
      {...props}
      className={`flex flex-col w-full max-w-sm mx-auto group ${props?.className}`}
    >
      <header className="relative z-10 px-3 transform translate-y-1/2">
        <h2 className="text-5xl lg:text-7xl font-tall">{title}</h2>
      </header>
      <div
        className={`relative w-full bg-gray-100 black pb-full transition-opacity duration-300 ${
          isLoaded ? "opacity-100" : "opacity-0"
        }`}
      >
        <img
          className="absolute top-0 object-cover w-full h-full transition-opacity duration-150 opacity-50 filter-grayscale group-hover:opacity-90"
          alt={title}
          ref={imgRef}
          style={{}}
          src={showImage ? img : undefined}
          onLoad={() => setLoaded(true)}
        />
      </div>

      <div className="flex justify-between px-3 py-4">
        <div className="flex-grow">
          <div className="flex justify-between">
            <h3
              className="mb-0 text-3xl md:text-3xl lg:text-5xl font-tall text-orange"
              dangerouslySetInnerHTML={{ __html: name }}
            ></h3>
            <div className="flex flex-shrink-0 mb-2 space-x-2">
              {linkedInLink && (
                <a
                  href={linkedInLink}
                  rel="noreferrer"
                  target="_blank"
                  className="block hover:text-orange"
                >
                  <FaLinkedin className="w-6 h-6 transition-colors duration-150" />
                </a>
              )}
              {twitterLink && (
                <a
                  href={twitterLink}
                  rel="noreferrer"
                  target="_blank"
                  className="block hover:text-orange"
                >
                  <FaTwitter className="w-6 h-6 transition-colors duration-150" />
                </a>
              )}
            </div>
          </div>
          <h4 className="text-sm xl:text-lg text-orange">{job}</h4>
          <a
            href={`mailto:${email}`}
            rel="noreferrer"
            className="text-sm text-black break-words transition-colors duration-150 xl:text-lg hover:text-orange"
          >
            {email}
          </a>
        </div>
      </div>
    </article>
  );
};

const ContactSection = () => {
  return (
    <>
      <div
        className="flex flex-col flex-1 py-16 -mt-1 overflow-hidden bg-white"
        data-scroll
        id="contact-wrapper"
      >
        <header className="relative mb-10 lg:mb-16">
          <img
            className="absolute top-0 object-contain w-full px-5 h-4/5"
            src={require("../../images/titles/contact.svg")}
            loading="lazy"
            data-scroll-target="#contact-wrapper"
            data-scroll-speed="-0.5"
            data-scroll
          />
          <div className="relative flex flex-col items-center mt-8">
            <h2 className="text-5xl text-center font-tall w-42 lg:text-6xl lg:flex lg:flex-col">
              <span
                data-scroll-target="#contact-wrapper"
                data-scroll-speed="0.1"
                data-scroll
              >
                Get
              </span>{" "}
              <span
                data-scroll-target="#contact-wrapper"
                data-scroll-speed="0.3"
                data-scroll
              >
                in
              </span>{" "}
              <span
                data-scroll-target="#contact-wrapper"
                data-scroll-speed="0.5"
                data-scroll
              >
                touch
              </span>
            </h2>
            <img
              className="w-72"
              src={require("../../images/paint-streak-g.svg")}
              loading="lazy"
            />
          </div>
        </header>
        <div className="grid w-full max-w-5xl gap-6 px-5 mx-auto sm:grid-cols-3">
          <ContactCard
            title="BIZDEV"
            name="Paul<br/>Ohanian"
            job="Chief Executive Officer"
            email="bizdev@unbrokenstudios.com"
            img={require("../../images/people/paul.jpg")}
            linkedInLink="https://www.linkedin.com/in/pohanian/"
            data-scroll-speed="-1"
          />
          <ContactCard
            title="Careers"
            name="Kimberly<br/>Turner"
            job="HR Manager"
            email="careers@unbrokenstudios.com"
            img={require("../../images/people/kimberly.jpg")}
            // linkedInLink="https://www.linkedin.com/in/paulatubs/"
            data-scroll-speed="-0.75"
          />
          <ContactCard
            title="Press"
            name="Robert<br/>Peeler"
            job="Community Manager"
            email="press@unbrokenstudios.com"
            img={require("../../images/people/robert.jpg")}
            linkedInLink="https://www.linkedin.com/in/rpeeler/"
            twitterLink="https://www.twitter.com/popcicle"
            data-scroll-speed="-0.4"
            className="relative top-1"
          />
        </div>
        {/* <footer className="flex justify-center mt-12 mb-5">
          <div className="flex space-x-3 md:space-x-4">
            <a
              href="#"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiLinkedinLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
            <a
              href="https://www.instagram.com/unbroken_studios/"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiInstagramLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
            <a
              href="https://www.facebook.com/UnbrokenStudios/"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiFacebookLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
            <a
              href="https://www.youtube.com/UnbrokenStudios"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiYoutubeLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
            <a
              href="https://www.twitch.tv/unbrokenstudios"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiTwitchLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
            <a
              href="https://twitter.com/UnbrokenStudios"
              target="_blank"
              className="inline-block p-3 transition-opacity duration-150 rounded-full bg-orange hover:opacity-75"
            >
              <RiTwitterLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
            </a>
          </div>
        </footer> */}
      </div>
    </>
  );
};

export default ContactSection;
