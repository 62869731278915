import { useState, useEffect, useRef, createRef } from "react";
import { FaArtstation, FaLinkedin, FaLink } from "react-icons/fa";

import { useInView } from "react-intersection-observer";

// Utils
import truncate from "../../utils/truncate";

// Data
import data from "../../content/team/team.json";

// Hooks
import useWindowSize from "../../hooks/useWindowResize";

const Filter = ({ onChange, value = "Artist" }) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [showAll, setShowAll] = useState(false);

  const options = [
    { label: "All", value: "" },
    { label: "Animators", value: "Animation" },
    { label: "Artists", value: "Artist" },
    { label: "Audio", value: "Audio" },
    { label: "Designers", value: "Design" },
    { label: "Engineers", value: "Engineers" },
    { label: "Operations", value: "Operations" },
    { label: "Producers", value: "Producers" },
    { label: "Quality Assurance", value: "QA" },
  ];

  const handleSelect = (option) => {
    if (option.value === selectedValue) {
      setShowAll(true);
    } else {
      setSelectedValue(option.value);
      onChange(option.value);
      setShowAll(false);
    }
  };

  return (
    <div className="flex flex-col xl:flex-row xl:space-x-5">
      {options.map((option) => (
        <span
          key={option.value}
          onClick={() => handleSelect(option)}
          className={`font-tall overflow-y-hidden max-h-0 transition-all duration-500 hover:opacity-100 cursor-pointer text-right ${
            option.value === selectedValue
              ? "opacity-100 max-h-10 xl:max-h-14"
              : "opacity-40 xl:max-h-14"
          } ${showAll && "max-h-10 xl:max-h-14"}`}
        >
          {option.label}
        </span>
      ))}
    </div>
  );
};

const TeamCard = ({
  name,
  title,
  department,
  isSelected,
  defaultState,
  profileImage,
  artstationLink = null,
  linkedInLink = null,
  externalLink = null,
  lazy = true,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "100%",
  });

  const [isLoaded, setLoaded] = useState(0);
  const [showImage, setShowImage] = useState(false);

  // const showImage = lazy === 'false' || !!inView

  useEffect(() => {
    if (!lazy || inView) {
      return setShowImage(true);
    }
  }, [inView, lazy]);

  // When an image is in the browser cache or is completed loading before react rehydration,
  // the `onload` may not be triggered. In order to ensure we have the correct "complete"
  // state, check the `complete` property after mounting
  const imgRef = createRef();
  useEffect(() => {
    if (
      imgRef &&
      imgRef.current &&
      imgRef.current.complete &&
      imgRef.current.naturalWidth
    ) {
      setLoaded(true);
    }
  });

  return (
    <article
      ref={ref}
      className={`relative rounded flex-col w-full max-w-sm mx-auto transform overflow-hidden transition-all duration-1000 bg-white group md:max-w-4xl hover:bg-black ${
        defaultState || isSelected
          ? "opacity-100  z-10 "
          : "hidden opacity-30 scale-90 pointer-events-none lg:flex"
      }`}
    >
      <div className="relative" style={{ paddingTop: "150%" }}>
        <div
          className={`absolute top-0 w-full h-full overflow-hidden bg-gray-200 transition-opacity duration-300 ${
            isLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          {profileImage && profileImage != "" && (
            <img
              className={`absolute top-0 object-cover w-full h-full transition-all duration-1000 transform opacity-30 scale-100 ${
                isSelected
                  ? "opacity-100 scale-105"
                  : "_mix-blend-multiply _filter-grayscale group-hover:opacity-100 group-hover:scale-105 group-hover:filter-none group-hover:mix-blend-none"
              }`}
              ref={imgRef}
              style={{}}
              src={showImage ? profileImage : undefined}
              alt={name}
              loading="lazy"
              width="230"
              height="350"
              onLoad={() => setLoaded(true)}
            />
          )}
        </div>
        <div
          className={`absolute bottom-0 w-full h-2/3 lg:h-1/2 transform bg-gradient-to-t from-black to-transparent transition-all duration-1000 opacity-0 group-hover:translate-y-0 group-hover:opacity-70 ${
            isSelected ? "opacity-70 translate-y-0" : "translate-y-14"
          }`}
          role="presentation"
        ></div>
        <div
          className={`absolute inset-0 flex flex-col p-3 transform transition-all duration-500 delay-50 opacity-0  group-hover:translate-y-0 group-hover:opacity-100 ${
            isSelected ? "opacity-100 translate-y-0" : "translate-y-7"
          }`}
        >
          <header className="flex mt-auto">
            <div className="flex-grow">
              <h3 className="mb-1 text-xl leading-6 tracking-wider text-white lg:text-2xl xl:text-2xl font-tall xl:leading-6">
                {truncate(name, 30)}
              </h3>
              <h4 className="text-sm tracking-wider text-white _leading-6 _text-lg _font-tall opacity-90 _lg:text-xl _xl:leading-6">
                {title}
              </h4>
            </div>
            <div className="flex items-end flex-shrink-0 ml-2">
              {externalLink && (
                <a
                  href={externalLink}
                  rel="noreferrer"
                  target="_blank"
                  className="ml-2 text-white hover:text-orange"
                >
                  <FaLink className="w-5 h-5 transition-colors duration-150" />
                </a>
              )}
              {linkedInLink && (
                <a
                  href={linkedInLink}
                  rel="noreferrer"
                  target="_blank"
                  className="ml-2 text-white hover:text-orange"
                >
                  <FaLinkedin className="w-5 h-5 transition-colors duration-150" />
                </a>
              )}
              {artstationLink && (
                <a
                  href={artstationLink}
                  rel="noreferrer"
                  target="_blank"
                  className="ml-2 text-white hover:text-orange"
                >
                  <FaArtstation className="w-5 h-5 transition-colors duration-150" />
                </a>
              )}
            </div>
          </header>
        </div>
      </div>
    </article>
  );
};
const TeamSection = ({ team }) => {
  const [selectedTag, setSelectedTag] = useState("");

  useEffect(() => {
    if (window?.innerWidth > 1024) {
      setSelectedTag("");
    } else {
      setSelectedTag("Artist");
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col flex-1 py-12 bg-white" data-scroll>
        <header
          className="relative z-20 w-full mx-auto mb-5 px-7 max-w-7xl 2xl:max-w-8xl"
          style={{ marginTop: "-7vw" }}
          id="team-header-wrapper"
        >
          <div
            className="absolute inset-0 mx-5"
            data-scroll-direction="horizontal"
            data-scroll-target="#team-header-wrapper"
            data-scroll-speed="-0.5"
            data-scroll
          >
            <img
              className="absolute top-0 object-contain object-left w-full h-full opacity-90"
              src={require("../../images/titles/team.svg")}
              loading="lazy"
            />
          </div>
          <div
            className="relative flex flex-col pt-40 pb-8 2xl:pt-48 2xl:pb-10"
            data-scroll-direction="horizontal"
            data-scroll-target="#team-header-wrapper"
            data-scroll-speed="-1"
            data-scroll
          >
            <h2
              className="flex flex-col mt-1 text-2xl ml-14 md:mt-12 md:text-5xl font-wide-bold lg:text-5xl 2xl:text-7xl "
              style={{ lineHeight: "0.8em" }}
            >
              <span
                data-scroll
                data-scroll-direction="horizontal"
                data-scroll-target="#team-header-wrapper"
                data-scroll-speed="-0.3"
              >
                GET TO
              </span>{" "}
              <span
                data-scroll
                data-scroll-direction="horizontal"
                data-scroll-target="#team-header-wrapper"
                data-scroll-speed="-0.5"
              >
                KNOW US
              </span>
            </h2>
          </div>
        </header>
        <div
          className="relative z-10 flex w-full px-5 py-6 mx-auto text-3xl text-gray-700 max-w-7xl 2xl:max-w-8xl xl:text-4xl"
          style={{ willChange: "opacity" }}
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-target="#team-header-wrapper"
          data-scroll-speed="-0.1"
        >
          <span className="mr-auto font-tall">Filter By:</span>
          <Filter
            key={selectedTag}
            onChange={(value) => setSelectedTag(value)}
            value={selectedTag}
          />
        </div>
        <div className="relative w-full mx-auto">
          {/* <div className="absolute bottom-0 w-full border-2 opacity-75 pointer-events-none -top-24 border-orange"></div> */}
          <div className="grid w-full grid-cols-2 gap-2 px-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:grid-cols-8 3xl:grid-cols-10 4xl:grid-cols-12">
            {team
              ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map((teamMember) => (
                <TeamCard
                  name={teamMember.name}
                  title={teamMember.title}
                  profileImage={teamMember["profile-img"]}
                  department={teamMember.department}
                  key={teamMember.name}
                  artstationLink={teamMember.artstation_link}
                  linkedInLink={teamMember.linkedin_link}
                  externalLink={teamMember.external_link}
                  isSelected={teamMember.department === selectedTag}
                  defaultState={selectedTag === ""}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamSection;
