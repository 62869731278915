import { useState } from "react";
import Img from "react-optimized-image";

import { useStore } from "../../store/store";

import LazyImage from "../LazyImage";

import BGImg from "../../public/assets/images/history/history-bg-full.jpg";
import BGImgLqip from "../../public/assets/images/history/history-bg-full.jpg?lqip";

const HistorySection = () => {
  // const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false);
  const noOfTeamMembers = useStore((state) => state.noOfTeamMembers);

  return (
    <div id="history-wrapper" className="relative bg-blue-light">
      <img
        className="absolute right-0 z-10 object-contain object-right w-1/2 h-full pointer-events-none"
        style={{ marginTop: "-4vw" }}
        src={"/assets/images/history/history-paint-streak.png"}
        data-scroll=""
        data-scroll-speed="2"
        data-scroll-position="top"
        data-scroll-target="#history-wrapper"
      />

      <div className="relative flex flex-col flex-1 px-5 py-12 overflow-hidden lg:py-64">
        <div className="absolute top-0 bottom-0 right-0 w-full h-full bg-blue-light opacity-30 lg:opacity-100">
          <LazyImage
            className="absolute top-0 bottom-0 right-0 object-cover w-full h-full"
            placeholderClassName="absolute inset-0 w-full h-full"
            src={BGImg}
            lqip={BGImgLqip}
            observerOptions={{ threshold: 0.5 }}
          >
            <Img src={BGImg} webp sizes={[400, 1200, 2800]} />
          </LazyImage>
        </div>

        <div
          className="absolute top-0 w-full h-32 pointer-events-none bg-gradient-to-b from-blue-light to-transparent"
          role="presentation"
        ></div>
        <div
          className="absolute bottom-0 w-full h-32 pointer-events-none bg-gradient-to-t from-blue-light to-transparent"
          role="presentation"
        ></div>
        <div className="relative w-full mx-auto max-w-7xl">
          <div className="relative flex flex-col max-w-lg pr-16 xl:pr-0 2xl:text-xl">
            <h2
              className="flex flex-col mb-10 text-3xl leading-5 text-left text-black md:leading-9 md:text-5xl font-wide-bold lg:text-5xl lg:leading-10 2xl:text-6xl 3xl:text-7xl"
              style={{ lineHeight: "0.8em" }}
            >
              <span>Empowering</span> <span>Individual</span>
              <span>Impact</span>
            </h2>
            <h3 className="text-2xl text-white lg:text-4xl font-tall 2xl:text-5xl">
              OUR PAST
            </h3>
            <p className="leading-loose">
              Unbroken Studios is an LA based team of {noOfTeamMembers}{" "}
              developers with proven track records of working on some of the
              most memorable franchises such as Battlefield, Call of Duty, God
              of War and the Arkham Series.
            </p>
            <h3 className="mt-8 text-2xl text-white lg:text-4xl font-tall 2xl:text-5xl">
              OUR PHILOSOPHY
            </h3>
            <p className="leading-loose">
              At the heart of our culture is creating a small studio with a
              strong sense of community and the ability for everyone to have an
              impact. We want people who are great developers but also great
              colleagues so that we have a team that everyone loves to work in
              as we embark on the studio's journey!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistorySection;
