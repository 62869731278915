import React, { memo, useEffect } from "react";
import { useRouter } from "next/router";
import { Client, Prismic } from "../services/prismic.service";

import {
  SmoothScrollProvider,
  SmoothScrollContext,
} from "../contexts/SmoothScroll.context";

import { useStore } from "../store/store";

// Sections
import HeroSection from "../components/sections/Hero.section";
import SocialSection from "../components/sections/Social.section";
import CareersSection from "../components/sections/Careers.section";
// import ArticlesSection from "../components/sections/Articles.section";
import ContactSection from "../components/sections/Contact.section";
import HistorySection from "../components/sections/History.section";
import GamesSection from "../components/sections/Games.section";
import StudioHistorySection from "../components/sections/StudioHistory.section";
import ValuesSection from "../components/sections/ValuesAlt.section";
import TeamSection from "../components/sections/Team.section";
import StudioSection from "../components/sections/Studio.section";

// import ImageSection from "../components/sections/Image.section";

// Components
import BackToTop from "../components/BackToTop";
import Header from "../components/partials/Header";

// Hooks
// import useKonamiCode from "../hooks/useKonamiCode"

export function HomePage(props) {

  const router = useRouter();
  // const { query } = router;

  // const [isProtected, setIsProtected] = React.useState(true);

  // useEffect(() => {
  //   console.log('query', query.pass)
  //   if(query.pass === 'letmein'){
  //     setIsProtected(false);
  //   }
  // }, [query.pass])
  



  // useKonamiCode(() => {

  //   alert('Good job 🥳');

  // });

  const setNoOfPositions = useStore((state) => state.setNoOfPositions);
  const setNoOfTeamMembers = useStore((state) => state.setNoOfTeamMembers);

  if(props.positions?.length > 0){
    setNoOfPositions(props.positions.length)
  }

  if(props.team?.length > 0){
    setNoOfTeamMembers(props.team.length)
  }

  // if(isProtected) return null;

  

  return (
    <SmoothScrollProvider options={{ smooth: true }}>
      <div className="">
        <Header />
        {/* <BackToTop /> */}
        <HomePageLayout {...props} />
      </div>
    </SmoothScrollProvider>
  );
}

HomePage.title = "Unbroken Studios";
HomePage.layout = "site";

export default HomePage;

export async function getStaticProps(context) {
  const client = Client();
  //const doc = await client.getByUID('page', params.uid)
  const teamQuery = await client.query(
    Prismic.Predicates.at("document.type", "team"),
    { pageSize: 100, orderings: "[my.team.name]" }
  );
  const positionsQuery = await client.query(
    Prismic.Predicates.at("document.type", "position"),
    { pageSize: 50 }
  );
  // orderings: "[my.blog_post.date desc]"

  // Format
  const team = teamQuery.results.map((teamMember) => ({
    name: teamMember.data?.name[0]?.text || null,
    title: teamMember.data?.title[0]?.text || null,
    department: teamMember.data?.department,
    "profile-img": teamMember.data?.profile_image?.url || null,
    external_link: teamMember.data?.external_link?.url || null,
    linkedin_link: teamMember.data?.linkedin_link?.url || null,
    artstation_link: teamMember.data?.artstation_link?.url || null,
  }));

  const positions = positionsQuery.results.map((position) => ({
    title: position.data?.title[0]?.text || null,
    department: position.data?.department || null,
    image: position.data?.image?.url || null,
    external_link: position.data?.external_link?.url || null
  }));

  let posts = [];

  return {
    props: {
      instagramPosts: posts,
      team,
      positions
    },
  };
}

const HomePageLayout = ({ instagramPosts, team, positions }) => {
  // console.log({ team });
  // console.log({ positions });
  /**
   * SECTION
   **/

  const Section = memo(({ id, children, className = "" }) => {
    return (
      <section
        data-id={id}
        // sref={ref}
        className={className}
        data-scroll-section
        data-scroll
        data-scroll-id={id}
        style={{ willChange: "transform" }}
      >
        {children}
      </section>
    );
  });

  return (
    <>
      <div className="overflow-hidden">
        <Section id="hero">
          <HeroSection />
        </Section>
        <Section id="about">
          <HistorySection />
        </Section>
        <Section id="games">
          <GamesSection />
        </Section>
        <Section id="studio-history">
          <StudioHistorySection />
        </Section>
        <Section id="values">
          <ValuesSection />
        </Section>
        <Section id="team">
          <TeamSection team={team} />
        </Section>
        <Section id="social" className="relative z-10">
          <SocialSection instagramPosts={instagramPosts} />
        </Section>
        <Section id="studio">
          <StudioSection />
        </Section>
        <Section id="careers">
          <CareersSection positions={positions} />
        </Section>

        <Section id="contact">
          <ContactSection />
        </Section>
      </div>
    </>
  );
};