import React, { useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { SmoothScrollContext } from "../../contexts/SmoothScroll.context";
import Img from "react-optimized-image";

const GamesSection = () => {
  const { scroll } = useContext(SmoothScrollContext);

  const [currentIndex, setCurrentIndex] = useState(0);

  const games = [
    {
      title: "",
      content: (
        <>
          {/* <p className="mb-3">
            We can't say much about our exciting projects but we think you'll be
            surprised at what they are!
          </p>
          <p>
            Stay tuned for some big announcements or reach out if you'd like to {' '}
            <a
              href="#"
              className="font-semibold transition-all duration-150 text-blue hover:opacity-75"
              onClick={() => scroll.scrollTo(`[data-id="careers"]`)}
            >
              join the team
            </a> and work on them!
          </p> */}
          <p className="mb-3">
            Our team is working on 2 extremely exciting projects right now. Stay
            tuned for the next big announcement and reach out if you'd like to{" "}
            <a
              className="font-semibold transition-all duration-150 cursor-pointer text-blue hover:opacity-75"
              onClick={() => scroll.scrollTo(`[data-id="careers"]`)}
            >
              join the team
            </a>{" "}
            and work on either game!
          </p>
        </>
      ),
      background_img: (
        <Img
          className="absolute top-0 object-cover w-full h-full bg-black opacity-40"
          src={require("../../images/games/Raven_SeekerDraco_Screenshot_052124.jpg")}
          loading="lazy"
          // data-scroll-offset="-50%, 0%"
          // data-scroll-position="top"
          // data-scroll=""
          // data-scroll-speed="-1.5"
          webp
          sizes={[400, 1200, 2200]}
        />
      ),
    },
    {
      title: "Harry Potter: Quidditch Champions",
      content: (
        <>
          <p className="mb-3">
            Our team has been developing Harry Potter: Quidditch Champions, an
            Online Multiplayer team competitive experience featuring the best
            sport in the Wizarding World!{" "}
            <a
              href="https://www.quidditchchampions.com/"
              target="_blank"
              className="font-bold"
            >
              Available Sep 3, 2024
            </a>{" "}
            for PlayStation®5, PlayStation®4, Xbox Series X|S, Xbox One,
            Nintendo Switch™ and PC (Steam and Epic)!
          </p>
        </>
      ),
      background_img: (
        <Img
          className="absolute top-0 object-cover w-full h-full bg-black"
          src={require("../../images/games/Raven_BeaterRain_Screenshot_052124.jpg")}
          webp
          sizes={[1200, 2200]}
        />
      ),
    },
    {
      title: "Suicide Squad: Kill the Justice League",
      content: (
        <>
          <p className="mb-3">
            Unbroken Studios is proud to be working with Rocksteady Studios on
            their game,{" "}
            <strong className="font-bold">
              Suicide Squad: Kill the Justice League
            </strong>
            .
          </p>
        </>
      ),
      background_img: (
        <Img
          className="absolute top-0 object-cover object-right w-full h-full bg-black lg:object-center"
          src={require("../../images/games/Suicide_Squad_Kill_the_Justice_League_bg.jpg")}
          webp
          sizes={[1200, 2200]}
        />
      ),
    },
  ];

  const GameTitle = ({ selectedIndex }) => {
    return (
      <AnimatePresence>
        <motion.div
          className="absolute top-0 left-0 right-0 flex flex-1"
          key={selectedIndex}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ ease: "easeOut", duration: 0.3 }}
        >
          <h2
            className="text-2xl text-white xs:text-3xl sm:text-4xl md:text-4xl font-wide-bold xl:text-5xl 2xl:text-6xl 3xl:text-7xl"
            style={{ lineHeight: "0.8em" }}
            dangerouslySetInnerHTML={{ __html: games[selectedIndex].title }}
          >
            {/* {typeof selectedIndex === "number" &&
              games[selectedIndex] &&
              games[selectedIndex].title &&
              games[selectedIndex].title} */}
          </h2>
        </motion.div>
      </AnimatePresence>
    );
  };

  const GameContent = ({ selectedIndex }) => {
    return (
      <AnimatePresence>
        <motion.div
          className="top-0 "
          key={selectedIndex}
          initial={{ opacity: 0, x: -50, position: "absolute" }}
          animate={{ opacity: 1, x: 0, position: "relative" }}
          exit={{ opacity: 0, x: 50, position: "absolute" }}
          transition={{ ease: "easeOut", duration: 0.5 }}
        >
          <div className="block">{games[selectedIndex].content}</div>
        </motion.div>
      </AnimatePresence>
    );
  };

  const GameImage = ({ selectedIndex }) => {
    return (
      <AnimatePresence>
        <motion.div
          className="absolute top-0 object-cover w-full h-full"
          style={{
            marginLeft: -50,
            marginRight: -50,
            width: "calc(100% + 100px)",
          }}
          key={selectedIndex}
          width="2200"
          height="1400"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 50 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
        >
          {games[selectedIndex].background_img}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="relative bg-black" id="games-wrapper" data-scroll>
      <div className="relative flex flex-col justify-center flex-1 pt-16 pb-12 overflow-hidden bg-black lg:pb-48 lg:pt-40 lg:py-64 xl:pt-72 xl:pb-96 _xl:h-hero-lg">
        <div
          className="absolute top-0 object-cover w-full h-full bg-black"
          data-scroll-target="#games-wrapper"
        >
          {/* <Img
            className="absolute top-0 object-cover w-full h-full bg-black"
            src={require("../../images/games/games-bg.jpg")}
            data-scroll=""
            data-scroll-speed="-1.5"
            loading="lazy"
            // data-scroll-offset="-50%, 0%"
            // data-scroll-position="top"

            webp
            sizes={[400, 1200, 2200]}
          /> */}
          <div
            className="absolute top-0 w-full h-full"
            data-scroll=""
            data-scroll-speed="-1.5"
          >
            <GameImage selectedIndex={currentIndex} />
          </div>
        </div>

        <div className="relative w-full px-5 mx-auto max-w-8xl lg:flex">
          <div className="lg:absolute lg:w-1/2 lg:flex lg:flex-col lg:pr-12">
            <img
              className={`w-2/3 h-auto lg:w-3/4 transition-opacity duration-500 ${
                currentIndex !== 0 ? "opacity-0" : "opacity-100"
              }`}
              src={require("../../images/games/games-title-blue.png")}
              loading="lazy"
            />
            <div className="relative block px-4 mb-6 -mt-16 md:ml-6 lg:ml-16 md:-mt-32 lg:-mt-48">
              <h2
                className="text-2xl text-white xs:text-3xl sm:text-4xl md:text-4xl font-wide-bold xl:text-5xl 2xl:text-7xl "
                style={{ lineHeight: "0.8em" }}
              >
                &nbsp;
                <br />
                &nbsp;
                <br />
                &nbsp;
              </h2>
              <GameTitle selectedIndex={currentIndex} />
            </div>

            <div className="relative h-24 px-4 mb-6 text-sm text-white md:text-base md:w-4/5 lg:ml-auto xl:text-lg xl:leading-loose">
              <GameContent selectedIndex={currentIndex} />
            </div>
          </div>
          <div className="flex-1">
            {/* <img
              className="w-full h-auto"
              // style={{height: "50vw"}}
              src={require("../../images/games/games-boxes+overlay.png")}
              loading="defer"
            /> */}
            <div className="lg:w-1/2 lg:ml-auto">
              <div className="grid max-w-3xl grid-cols-2 grid-rows-2 mx-auto">
                <div
                  className="relative col-span-1 transition-all duration-500 transform bg-white cursor-pointer group hover:scale-105 hover:shadow-md hover:z-20"
                  onMouseOver={() => setCurrentIndex(1)}
                  onMouseOut={() => setCurrentIndex(0)}
                >
                  <div className="pb-full"></div>
                  <div className="absolute inset-4">
                    <img
                      className="absolute top-0 object-contain w-full h-full"
                      // style={{height: "50vw"}}
                      src={require("../../images/games/quidditch-box.png")}
                      loading="lazy"
                    />
                  </div>
                  <img
                    src={require("../../images/games/games-hover.png")}
                    className={`absolute max-w-none transition-opacity duration-300 ${
                      currentIndex === 1 ? "opacity-100" : "opacity-0"
                    }`}
                    style={{
                      top: -20,
                      left: -20,
                      width: "calc(100% + 40px)",
                      height: "calc(100% + 40px)",
                    }}
                  />
                </div>
                <div className="relative col-span-1">
                  <div className="pb-full"></div>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-2xl font-thin text-blue sm:text-4xl md:text-5xl lg:text-5xl 2xl:text-7xl">
                      +
                    </span>
                  </div>
                </div>
                <div className="relative col-span-1 row-start-2">
                  <div className="pb-full"></div>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-2xl font-thin text-blue sm:text-4xl md:text-5xl lg:text-5xl 2xl:text-7xl">
                      +
                    </span>
                  </div>
                </div>
                <div
                  className="relative z-10 col-span-1 col-start-2 row-start-2 transition-all duration-500 transform bg-white cursor-pointer group hover:scale-105 hover:shadow-md hover:z-20"
                  onMouseOver={() => setCurrentIndex(2)}
                  onMouseOut={() => setCurrentIndex(0)}
                >
                  <div className="pb-full"></div>
                  <div className="absolute inset-0">
                    <img
                      className="absolute top-0 object-cover w-full h-full"
                      src={require("../../images/games/Suicide_Squad_Kill_the_Justice_League_logo.jpg")}
                      loading="lazy"
                    />
                  </div>
                  <img
                    src={require("../../images/games/games-hover.png")}
                    loading="lazy"
                    className={`absolute max-w-none transition-opacity duration-300 ${
                      currentIndex === 2 ? "opacity-100" : "opacity-0"
                    }`}
                    style={{
                      top: -20,
                      left: -20,
                      width: "calc(100% + 40px)",
                      height: "calc(100% + 40px)",
                    }}
                  />
                </div>
                {/* <div className="relative col-span-1 col-start-2 row-start-2 bg-white">
                  <div className="pb-full"></div>
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src={require("../../images/games/question-box.png")}
                    loading="defer"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="absolute top-0 left-0 w-full h-auto pointer-events-none"
        style={{ marginTop: "-2.7vw" }}
        src={require("../../images/backgrounds/history-bottom-cap-fade.png")}
        loading="lazy"
      />
      <img
        className="absolute bottom-0 left-0 w-full h-auto -mb-1 pointer-events-none"
        src={require("../../images/backgrounds/values-top-cap-blue.svg")}
        loading="lazy"
      />
    </div>
  );
};

export default GamesSection;
