import Link from "next/link";
import {
  RiInstagramLine,
  RiLinkedinLine,
  RiYoutubeLine,
  RiTwitchLine,
  RiTwitterLine,
  RiFacebookLine,
} from "react-icons/ri";
import { useInView } from "react-intersection-observer";
import InstagramSlider from "../InstagramSlider";

const SocialSection = ({ instagramPosts = [] }) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
  });

  if (instagramPosts.length === 0) {
    instagramPosts = [
      {
        id: "2502725707965960884",
        caption:
          "Season 3 winners and employees new and old continue to enjoy some free donut wins, hooray! Check out our open positions—get in on this! #donuts #donuts🍩 #donutday #desserts #battletendency #royalseat #gamestudio #devstudio #videogames #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/146677908_507129673604632_1135137067121226152_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=104&_nc_ohc=nwzbKItz9M4AX8FrQNP&tp=1&oh=87a2058980c0d80172bdd1fd666cc3b5&oe=604A6E20",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CK7d8V5jJK0",
        comments: 0,
        likes: 18,
        date: 1612568175,
      },
      {
        id: "2497697403844315328",
        caption:
          "Season 3 for Donut Day! This time we managed to wrangle new top-level entries, congrats CEO Paul for winning this week! #donuts #donutday #gamedev #gamestudio #gamedesigners #videogames #socal #losangeles #totallynotrigged",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/143354096_1075639802862320_1242312934259769211_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=108&_nc_ohc=IjRUdi3XkWwAX8KkyFM&tp=1&oh=2f31354a740cbabb62cfcc059e25700d&oe=604CBBDB",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CKpmo4wjkjA",
        comments: 1,
        likes: 18,
        date: 1611968754,
      },
      {
        id: "2484485354582510940",
        caption:
          "Season 2 donuts are winding down, congrats to our latest recipients—of both donuts and #photoshopedit #donuts #donuts🍩 #donutday #gamedev #gamestudio #photoshop #classicartmemes #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/137532059_403490824068091_5485230441328268211_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=107&_nc_ohc=fi8-kfeL1BoAX9R1WZ6&tp=1&oh=19c3ce8908ee9baa2092d3a1f404317c&oe=604B510B",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CJ6qkRBjQ1c",
        comments: 0,
        likes: 14,
        date: 1610393755,
      },
      {
        id: "2470669967885770753",
        caption:
          "Closing up shop for the holidays soon, we wanted to leave you our latest in donut day #photoshop and this weeks is better than it has any right to be... #donuts #donutday🍩 #king #ninja #ninjagaiden #madmax #warboys #gamedev #devstudio #gamedevelopers #videogames #socal #losangeles #wfh #holidays #holidayseason",
        src:
          "https://scontent-lht6-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/132098271_1676786392503675_3548573024501703406_n.jpg?_nc_ht=scontent-lht6-1.cdninstagram.com&_nc_cat=103&_nc_ohc=-V4QSKn5dfoAX8QLlft&tp=1&oh=0d189020bb290af307dc320d0d225fb6&oe=604D6B38",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CJJlT7hDqAB",
        comments: 1,
        likes: 17,
        date: 1608746833,
      },
      {
        id: "2467263116141905883",
        caption:
          "Watched some unforgettable speeches at our company virtual party. Thank you so much everyone who contributed to these important end of the year inspiring messages! #inspiring #companyparty #toast #gamestudio #leadership #fireside #terminator #gunsmith #gamedev #ceo #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/131468007_749195489284993_5414477939391040834_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=7XsNTbu2ASYAX-Ug5cH&tp=1&oh=10c97d26c67ec86e19b85be3577ae3c9&oe=604B7BF8",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CI9ertlD8fb",
        comments: 0,
        likes: 24,
        date: 1608340704,
      },
      {
        id: "2467090813479155980",
        caption:
          "Enjoying our virtual studio gift exchange! Thanks for the thoughtful gifts all! #secretsanta #giftexchange #officeparty #holidayparty #wfh #zoom #virtual #gamestufio #gamedev #holidayseason #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c315.0.810.810a/s640x640/131853044_752424438732145_7892197326062135263_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=G4QquH6KdvcAX-I1AIK&tp=1&oh=b5d048466b3e503dca70ae3776050a3e&oe=604C3657",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CI83gYODfkM",
        comments: 0,
        likes: 25,
        date: 1608320164,
      },
      {
        id: "2462190012130623494",
        caption:
          "What a beautiful two weeks of donut winners we have had these grave Dec morns...wait is that a cookie? #donuts🍩 #donutday #sweets #december #gamedev #gamestudio #studio #videogames #developers #socal #losangeles #lotr #indianinthecupboard #drstrange #donutchamp",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/131294320_137678138135493_8799064526376857675_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=109&_nc_ohc=fVDqbq04-fUAX_m6fO9&tp=1&oh=78a8ee3f04750f6d686711120205721e&oe=604D1119",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CIrdMVSj4AG",
        comments: 0,
        likes: 20,
        date: 1607735943,
      },
      {
        id: "2457048035256756126",
        caption:
          "The studio continues to grow! Looking to join our team and work on AAA games!? Check out our open positions and apply today #jobs #openings #gamestudio #devstudio #videogames #gamejobs #designer #engineer #qa #hr #conceptartist #technicalart #fx #gameplay #vfx #ui #uidesign #socal #losangeles #wfh #workfromhome",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c343.0.753.753a/s640x640/129715898_867455860726837_5220873464545419247_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=0QjpF6gjsDMAX_ced6N&tp=1&oh=c7e9c454aa011cc7d1655fc826ee1892&oe=604D1801",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CIZMCuJjOee",
        comments: 0,
        likes: 24,
        date: 1607122972,
      },
      {
        id: "2446935255518326316",
        caption:
          "Another fantastic donut day! There were some other killer shops from today but, well you just had to be there... #donutday #donuts🍩 #donuts #photoshop #neverendingstory #backstreetboys #nda #insidejoke #devstudio #gamedevs #socal #losangeles #itotallywasntlategettingdonutsoutiswear",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/126095807_3807520599335302_691460611565123061_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=110&_nc_ohc=78Aar1kLn9gAX_kXwWN&tp=1&oh=ad280139da093a3908394c85fab1e01d&oe=604AD6A2",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CH1QqaFjK4s",
        comments: 0,
        likes: 14,
        date: 1605917435,
      },
      {
        id: "2441793730656372853",
        caption:
          "Donuts can lead to many things, even disaster. This is fine. Congrats to this weeks winners, Shelby, Jon and Ben!! Have a great weekend team! #donutday #donuts #studio #sweets #devteam #devstudio #videogames #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/124832402_2478966795744511_4492671803458227028_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=107&_nc_ohc=pCVZLIhYftIAX__bvHl&tp=1&oh=436ce913b854eb47d79656a5000e5d36&oe=604E14FC",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CHi_nX6jIh1",
        comments: 0,
        likes: 17,
        date: 1605304517,
      },
      {
        id: "2436752668367845840",
        caption:
          "A tumultuous week in America can only end with donut deliveries to three lucky team members depicted here in organic unedited formats...\nPlus big welcome to our new CFO/COO Anthony! #donuts #donutday #gamestudio #donutfriday #workfromhome #gamedev #videogames #operations #newemployee #welcome #losangeles #socal",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/123640748_759545741303977_7356788244864134838_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=h4ZmIXnjrFkAX9T15K4&tp=1&oh=762f91ee408a7d102abf81d32430d346&oe=604D90AF",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CHRFaQzjPnQ",
        comments: 0,
        likes: 20,
        date: 1604703576,
      },
      {
        id: "2432392631411379705",
        caption:
          "Happy Halloween from Unbroken Studios! Our team is always hyped to dress for the occasion and we wouldn’t miss out on sharing our costumes even remote #wfh #gamestudio #videogames #gamedev #Halloween #halloweencostume #candy #goose #pirate #ladyinthelake #harley #anthonyfantano #slowbro #amongus #bloodmage #workfromhome #socal #losangeles",
        src:
          "https://scontent-lht6-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/123128059_274735340554002_6147547635760200311_n.jpg?_nc_ht=scontent-lht6-1.cdninstagram.com&_nc_cat=106&_nc_ohc=2QzX6N4GH8wAX8_0vDa&tp=1&oh=c39407dbbfe48f6b57b866e30274cfe2&oe=604DCD46",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CHBmDX9jY35",
        comments: 1,
        likes: 28,
        date: 1604183819,
      },
      {
        id: "2431543810011950529",
        caption:
          "Usually, we colllage our donut photoshops but on the rare occasion our artists step out and do something truly amazing, the work may stand alone #donutday #thisistheway #steveunbroken #doordash #gamedev #gameart #artistsofinstagram #gamestudio #halloween #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c0.180.1440.1440a/s640x640/122942623_390093605733033_8028157504334308847_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=109&_nc_ohc=P2SmpYIYP2oAX9-9WgT&tp=1&oh=834cd5b6a51ee24d3f983295dfc296a9&oe=604B64D1",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CG-lDZbj-3B",
        comments: 0,
        likes: 18,
        date: 1604082631,
      },
      {
        id: "2428918222163844986",
        caption:
          "Welcome to Unbroken Studios our four new QA staff! We’re excited to have you join our projects and help us test our titles in development #gamedev #gamejobs #devstudio #qualityassurance #qa #devteam #newstaff #losangeles #socal #heresadonutforyou",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c343.0.753.753a/s640x640/122804854_972748769883265_961545786414874640_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=Leh06xwCxvAAX9vdjcF&tp=1&oh=a01c8e38a0eef5c2233fca9e381e5dff&oe=604D92BD",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CG1QEEMjJ96",
        comments: 0,
        likes: 16,
        date: 1603769637,
      },
      {
        id: "2426664563803786257",
        caption:
          "Catching up on some amazing Donut Day photoshops! Season 2 has produced interesting and often violently happy people! Congrats all! #donutday #donuts #donuts🍩 #desserts #photoshop #lotr #jurassicworld #peanuts #gamedev #gamestudio #videogames #weareaddictedtodonutsanditsalarming #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/122190307_266654744730712_8269049770233408299_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=104&_nc_ohc=-4a5HGtRtPcAX8xzV8G&tp=1&oh=74af0617afc1b5a6f6abf8965c4c1930&oe=604D1E40",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CGtPpBSjYwR",
        comments: 0,
        likes: 21,
        date: 1603500980,
      },
      {
        id: "2406292569069613452",
        caption:
          "We're looking for new and eager talent to work on multiple AAA action titles, currently #workfromhome / eventually joining the office!\nCheck out our open positions and apply today unbrokenstudios.com\n#gamedev #gamestudio #gamejobs #devstudio #videogames #AAA #wfh #losangeles #socal",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/e35/c233.0.511.511a/120136190_957344918078738_3473253023514554541_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=102&_nc_ohc=2atCJefskiMAX_rF5fF&tp=1&oh=fb51bc039a3541429d0d4a40b7451612&oe=604C6A05",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CFk3letDnGM",
        comments: 1,
        likes: 17,
        date: 1601072449,
      },
      {
        id: "2406175102527751217",
        caption:
          "It’s a new season of donut delivery. Some people suggested we switch to bagels, but Donuts will not be denied! Congrats on your early victories Chris, Eddie and Brian! #donutday #donuts🍩 #gamedev #gamestudio #videogames #socal #losangeles #workfromhome #homedelivery #season2",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/120092831_340142977221118_3441649207658104042_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=110&_nc_ohc=B7wV8MgH5YIAX9K6j-r&tp=1&oh=d5722752d5d0572306b7ec14419e210b&oe=604DEB0E",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CFkc4HdjkQx",
        comments: 0,
        likes: 17,
        date: 1601058446,
      },
      {
        id: "2403364604962854399",
        caption:
          "It’s never too late to celebrate a #donutday ! Well maybe after a second one... Congrats Andy, Jacob, and Tony! #donuts🍩 #donuts #latepost #gamedev #gamestudio #videogames #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/120026843_626310794921782_3047291105826798871_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=110&_nc_ohc=Iv1w6eH_jz0AX8j0o24&tp=1&oh=3a8f491476780628c0f36d027379318f&oe=604D7880",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CFad1_ojtX_",
        comments: 0,
        likes: 18,
        date: 1600723408,
      },
      {
        id: "2396921359793706837",
        caption:
          "Happy #nationalvideogameday🎮 from your friendly neighborhood video game devs! #videogames #gamedev #gamestudio #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/119163718_173921787572386_3522841481916395279_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=110&_nc_ohc=RYLU4Jbh1asAX_Jz1_7&tp=1&oh=ba2bcfc5e13c8978861c6bdc201d8b61&oe=604D5448",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CFDk0b9DNdV",
        comments: 0,
        likes: 26,
        date: 1599955314,
      },
      {
        id: "2396106778058004693",
        caption:
          "Donut day Friday all! Congrats Daehan, Megan and Jimmy! It’s no longer safe to go into the water...or skies. #donutday #howtotrainyourdragon #jaws #shark #donuts🍩 #gamedev #videogames #gamestudio #devstudio #losangeles #socal",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/119115717_148748070233210_3646518597709333745_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=102&_nc_ohc=lOvMvujBi5MAX87jtqc&tp=1&oh=5acf308cb344a63be67206209d911097&oe=604A6881",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CFArmtmDZjV",
        comments: 0,
        likes: 18,
        date: 1599858208,
      },
      {
        id: "2391145915962310626",
        caption:
          "More donut winners losing their heads over their hand delivered dozens! Congrats Gus, Sam and Michelle! #badjokes #donutday #donuts #photoshop #gamestudio #gamedev #videogames #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/118888143_334211507991492_6301618749170339235_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=100&_nc_ohc=HDt_idFXl5sAX-92BUn&tp=1&oh=a0fbe88a5e3e3d997514caba01e18a0b&oe=604B6992",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CEvDoqvDz_i",
        comments: 0,
        likes: 19,
        date: 1599266827,
      },
      {
        id: "2385937961344373556",
        caption:
          "It’s a special day when Eddie finally gets his donuts...only for the cruel fate of photoshopped memes steals them away once again! #donutday #noneforyou #photoshop #donuts #justice",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/118478318_756489961853959_3195119047176311614_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=107&_nc_ohc=cRW7xvdIap8AX_cPpRO&tp=1&oh=9e8206578e0deacabec826fba7ded382&oe=604CB658",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CEcje9CDRM0",
        comments: 4,
        likes: 22,
        date: 1598645991,
      },
      {
        id: "2385874392196084716",
        caption:
          "Our Design Director: Eddie finally won his donuts, but can he keep them! Congrats Eddie, and of course our other lucky winners Ted and Ji! Keep an eye on that pup... #donutday #donuts #gamestudio #gamedev #videogames #thieves #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/118590377_119668853185416_7094622511672237522_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=100&_nc_ohc=6LzDmSUhUOQAX8xGncm&tp=1&oh=1c114ea621cadff3e0ca90d240b4d4bd&oe=604D526F",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CEcVB5pjHPs",
        comments: 2,
        likes: 25,
        date: 1598638412,
      },
      {
        id: "2380818166408028279",
        caption:
          "Somewhat of a theme going for today’s donut winners who, unsurprisingly, didn’t include our hangry Designer Director tsk tsk.\nCongrats to Farren, Ben and Craig on their donut deliveries! #donutday #donuts🍩 #fridaytreat #pettreats #jealous #catsnack #socal #losangeles #gamestudio #gamedev #videogames",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/118288359_327395455069673_390569138757212571_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=105&_nc_ohc=9-qnlJzHL9wAX9iPyaw&tp=1&oh=98407b99baf4af76e11beea65bb9641e&oe=604A71A3",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CEKXYIbjCB3",
        comments: 2,
        likes: 29,
        date: 1598035663,
      },
      {
        id: "2375733242202290926",
        caption:
          "Another week of donut winners: Congrats Izzy, Jordan, and Shelby! One more week of winners on the way and then the cycle begins anew #donuts #donutday #weekendgoals #sweettooth #morning #gamedev #gamestudio #videogames #losangeles #socal",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/117391975_287631562537642_6286519405114223403_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=110&_nc_ohc=U6_sPsWhq04AX8xUz47&tp=1&oh=48822d6a07df1bd015ae0efa736d4e55&oe=604D6133",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CD4TMvujfru",
        comments: 0,
        likes: 21,
        date: 1597429493,
      },
      {
        id: "2370832840738707904",
        caption:
          "Another donut day passes gently into the afternoon. Congrats Dean, Mal and Robert latest #donuts recipients! #desserts #sweetlife #workfromhome #devstudio #videogames #gamedev #socal #losangeles",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/117227650_762700744493504_8986204464061301497_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=108&_nc_ohc=dLS9CYi2PUoAX94EgfA&tp=1&oh=c8317139a25a22845c0c98a054535380&oe=604BABC8",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CDm4-hOD5nA",
        comments: 0,
        likes: 23,
        date: 1596845320,
      },
      {
        id: "2365662051353709732",
        caption:
          "Some Summer Swag has just arrived for the employees at Unbroken Studios! Sit back relax and enjoy the weekend sipping and sockin’ #relaxing #swag #mug #pintglass #socks #summer #gamestudio #videogames #gamedev #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/e15/s640x640/116583023_334257777602054_687529233022079124_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=102&_nc_ohc=nZsLJaEhzEMAX915jEG&tp=1&oh=499bec73abfa206bb156d3eff9f02495&oe=6024DC54",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CDUhRoVj0yk",
        comments: 0,
        likes: 20,
        date: 1596228940,
      },
      {
        id: "2360663746236268385",
        caption:
          "Congratulations to more donut winners and photoshop victims! @burnknee.ramirez , Brian and Lee! #donuts #donutday #gamedev #videogames #devstudio #gamestudio #socal #losangeles",
        src:
          "https://scontent-lhr8-2.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/115805309_196516445149117_2081350966161321455_n.jpg?_nc_ht=scontent-lhr8-2.cdninstagram.com&_nc_cat=101&_nc_ohc=wd4TV-spMYMAX-aorpG&tp=1&oh=7b613bd0e3003878b067319761406738&oe=604D2AB1",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CDCwyt8jj9h",
        comments: 0,
        likes: 19,
        date: 1595633069,
      },
      {
        id: "2355419395075698223",
        caption:
          "More donut winners and more #photoshop to be done! Congrats Chris, Jon, and Prateek!!! #donuts #donutday #gamestudio #videogames #gamedev #losangeles #socal",
        src:
          "https://scontent-lht6-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/108322340_320674045754838_5513305075017376541_n.jpg?_nc_ht=scontent-lht6-1.cdninstagram.com&_nc_cat=106&_nc_ohc=q-D-36IkYR8AX-p9R-P&tp=1&oh=90f304059e4730dc5c89e03d15076b4c&oe=604CF31E",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CCwIXXUjW4v",
        comments: 0,
        likes: 22,
        date: 1595007894,
      },
      {
        id: "2350558265895356588",
        caption:
          "We had another donut drawing today with celebratory images from the winners. Notoriously patient engineer Brian was one of our winners and the generous benefactor of numerous #photoshop hijinx #donut #donutday #gamestudio #videogames #gamedev #losangeles #socal #weekendatbernies #doom #pulpfiction",
        src:
          "https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/s640x640/107550330_291941472156597_6373057598250365641_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_cat=109&_nc_ohc=69EaLWLjlvoAX-ykpjo&tp=1&oh=4dc877b4702ff1975d8278bc8f9461aa&oe=604A8F6B",
        width: 640,
        height: 640,
        url: "https://www.instagram.com/p/CCe3En-jQis",
        comments: 0,
        likes: 27,
        date: 1594428402,
      },
    ];
  }

  return (
    <>
      <div
        className="relative z-10 flex flex-col flex-1 pt-12 -mt-1 overflow-hidden bg-white"
        ref={ref}
      >
        {/* <h2>{`Header inside viewport ${inView}.`}</h2> */}
        <header
          className="relative flex items-center justify-between w-full mx-auto mb-5 px-7 max-w-7xl"
          data-scroll
          id="social-header-wrapper"
        >
          <div className="">
            <div
              className="absolute inset-0 mx-5"
              data-scroll-direction="horizontal"
              data-scroll-target="#social-header-wrapper"
              data-scroll-speed="-0.5"
              data-scroll
            >
              <img
                className="absolute top-0 object-contain object-left w-full h-full"
                src={require("../../images/titles/insta.svg")}
                loading="lazy"
              />
              <div className="absolute top-0 w-full h-full bg-gradient-to-l from-white to-transparent"></div>
            </div>
            <div
              className="relative flex flex-col pt-24 pb-12"
              data-scroll-direction="horizontal"
              data-scroll-target="#social-header-wrapper"
              data-scroll-speed="-1"
              data-scroll
            >
              <img
                className="w-full max-w-md -mt-4"
                src={require("../../images/paint-streak-c.svg")}
                loading="lazy"
              />
              <h2 className="flex flex-col mt-1 ml-6 text-2xl leading-5 md:leading-9 md:mt-12 md:text-5xl font-wide lg:text-5xl lg:leading-9">
                <span
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-target="#social-header-wrapper"
                  data-scroll-speed="-0.3"
                >
                  Follow
                </span>{" "}
                <span
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-target="#social-header-wrapper"
                  data-scroll-speed="-0.5"
                >
                  Our Hijinks
                </span>
              </h2>
            </div>
          </div>
          <div className="relative flex h-full"></div>
        </header>
        <div className="relative w-full mx-auto">
          <InstagramSlider/>
        </div>
        <footer className="relative " id="social-footer-wrapper">
          <div className="container flex flex-col items-center mx-auto my-5 sm:flex-row">
            <div className="block space-x-3 md:space-x-4">
              <a
                href="https://www.linkedin.com/company/unbrokenstudios"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
                data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.3"
              data-scroll
              >
                <RiLinkedinLine className="w-5 h-5 text-white lg:w-8 lg:h-8" />
              </a>
              {/* <a
                href="https://www.instagram.com/unbroken_studios/"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
              >
                <RiInstagramLine className="w-5 h-5 text-white lg:w-8 lg:h-8"/>
              </a> */}
              <a
                href="https://www.facebook.com/UnbrokenStudios/"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
                data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.25"
              data-scroll
              >
                <RiFacebookLine className="w-5 h-5 text-white lg:w-8 lg:h-8" />
              </a>
              <a
                href="https://www.youtube.com/UnbrokenStudios"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
                data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.2"
              data-scroll
              >
                <RiYoutubeLine className="w-5 h-5 text-white lg:w-8 lg:h-8" />
              </a>
              <a
                href="https://www.twitch.tv/unbrokenstudios"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
                data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.15"
              data-scroll
              >
                <RiTwitchLine className="w-5 h-5 text-white lg:w-8 lg:h-8" />
              </a>
              <a
                href="https://twitter.com/UnbrokenStudios"
                target="_blank"
                className="inline-block p-3 transition-opacity duration-150 rounded-full bg-blue-light hover:opacity-75"
                data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.1"
              data-scroll
              >
                <RiTwitterLine className="w-5 h-5 text-white lg:w-8 lg:h-8" />
              </a>
            </div>
            <a
              href="https://www.instagram.com/unbroken_studios/"
              target="_blank"
              className="flex flex-col mt-4 text-lg text-center text-black sm:-mt-2 sm:text-left sm:ml-8 "
              data-scroll-direction="horizontal"
              data-scroll-target="#social-footer-wrapper"
              data-scroll-speed="-0.0.5"
              data-scroll
            >
              {/* <RiInstagramLine className="w-5 h-5 mr-3" /> */}
              <span className="">Follow us at</span>
              <span className="font-bold transition-colors duration-150 text-blue-light hover:text-blue">
                @unbroken_studios
              </span>
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SocialSection;
