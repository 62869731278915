import React, { useState } from "react";
import RSSlider from "react-slick";

// https://codesandbox.io/s/framer-motion-swipe-endless-carousel-mb89i?file=/src/carousel/index.js

const Slider = ({ slides }) => {
  const [currentSlideIdx, setCurrentSlideIdx] = useState(0);
  const onSlideChange = (index) => console.log(`changed to slide ${index}`);
  const setSlideCustom = () => 1;

  const sliderRef = React.useRef();

  const BulletComponent = ({ onClick, isActive }) => (
    <li
      className={`w-3 h-3 bg-white ${
        isActive ? "opacity-100" : "opacity-50"
      } rounded-full mx-2`}
      onClick={onClick}
    />
  );

  const ArrowComponent = ({ className, onClick, direction }) => {
    return (
      <div
        className={`invisible text-white transition-opacity duration-200 transform -translate-y-1/2 cursor-pointer top-1/2 absolute z-20 opacity-0 group-hover:opacity-100 lg:visible ${
          direction === "right" ? "right-0" : "left-0"
        }`}
        onClick={onClick}
      >
        {direction === "left" && (
          <div className="p-4 transition-transform duration-200 transform hover:-translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M40.863 48.898a2.024 2.024 0 01-1.014-.271L1.014 26.206A2.029 2.029 0 010 24.45a2.026 2.026 0 011.014-1.755L39.849.273a2.029 2.029 0 012.028 0 2.03 2.03 0 011.014 1.757v44.843a2.03 2.03 0 01-1.014 1.756 2.027 2.027 0 01-1.014.269zM6.083 24.449l32.752 18.909V5.54z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
        {direction === "right" && (
          <div className="p-5 transition-transform duration-200 transform hover:translate-x-2">
            <svg
              className="w-5 h-5 m-10 text-white"
              viewBox="0 0 42.89 48.898"
              width="100%"
              height="100%"
            >
              <path
                d="M2.023 48.898a2.024 2.024 0 001.014-.271l38.835-22.421a2.029 2.029 0 001.014-1.756 2.026 2.026 0 00-1.014-1.755L3.037.273a2.029 2.029 0 00-2.028 0A2.03 2.03 0 00-.005 2.03v44.843a2.03 2.03 0 001.014 1.756 2.027 2.027 0 001.014.269zm34.78-24.449L4.055 43.355V5.537z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
      </div>
    );
  };

  const ArrowPrev = (props) => {
    const { onClick } = props;
    return <ArrowComponent onClick={onClick} direction="left" />;
  };

  const ArrowNext = (props) => {
    const { onClick } = props;
    return <ArrowComponent onClick={onClick} direction="right" />;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    pauseOnHover: false,
    // adaptiveHeight: true,
    //onLazyLoad: (slidesLoaded) => console.log(slidesLoaded),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
        },
      },
    ],
    afterChange: (current, next) => setCurrentSlideIdx(current),
    // beforeChange: (current, next) => this.setState({ slideIndex: next })
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    // <div className="w-full h-full group">
    <RSSlider ref={sliderRef} {...settings} className="bg-black group">
      {slides.map((slide, idx) => (
        <div
          className="flex flex-1 w-full h-full"
          key={slide}
          draggable="false"
        >
          {" "}
          {React.cloneElement(slide.component, {
            isActive: currentSlideIdx === idx,
            currentSlideIdx,
            idx,
            nextSlide,
          })}
        </div>
      ))}
    </RSSlider>
    // </div>
  );
};

export default Slider;
